<template>
  <v-autocomplete
    label="Subject"
    :items="subjects"
    item-text="title"
    item-value="id"
    @input="handleInput"
    v-model="subject"
    :return-object="returnObject"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    subject: "",
    subjects: [],
  }),

  async mounted() {
    await this.loadSubjects();
    this.subject = this.value;
  },

  methods: {
    handleInput() {
      this.$emit("input", this.subject);
    },
    loadSubjects() {
      return this.$axios.get(`/categories/subjects`).then((response) => {
        this.subjects = response.data.results;
      });
    },
  },
};
</script>

<style>
</style>