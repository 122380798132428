<template>
  <v-container>
    <v-dialog v-model="dialogRoomSubjectUpdate" width="500">
      <room-subject-update
        :roomSubject="selectedRoomSubject"
        @close="dialogRoomSubjectUpdate = false"
        v-if="dialogRoomSubjectUpdate"
      ></room-subject-update>
    </v-dialog>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ room.name }}</v-card-title>
          <v-card-subtitle>{{ room.code }}</v-card-subtitle>
          <v-divider></v-divider>
          <v-card-text
            ><span>Section:&nbsp;</span>{{ room.section }}</v-card-text
          ><v-card-text><span>Shift:&nbsp;</span>{{ room.shift }}</v-card-text
          ><v-card-text
            ><span>Session:&nbsp;</span>{{ room.session }}</v-card-text
          >
        </v-card></v-col
      >
    </v-row>
    <v-row>
      <DataTableSSR
        apiEndPoint="/rooms/room-subjects/"
        :queryParams="{
          room: $route.params.id,
        }"
        :headers="headers"
        instantLoad
      >
        <template #top-right>
          <v-btn outlined class="pa-2">New Subject</v-btn>
        </template>
        <template #[`item.teachers`]="{ item }">
          <v-chip
            v-for="teacher in item.teachers"
            :key="teacher.id"
            color="primary"
            text-color="white"
            small
            >{{ teacher.full_name }}</v-chip
          >
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn icon @click="showRoomSubjectUpdate(item)">
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
        </template>
      </DataTableSSR>
    </v-row>
  </v-container>
</template>

<script>
import RoomSubjectUpdate from "@/components/room/RoomSubjectUpdate.vue";
const DataTableSSR = () => import("@/components/global/DataTableSSR.vue");
export default {
  components: { DataTableSSR, RoomSubjectUpdate },
  data: () => ({
    room: {},
    room_subjects: [],
    selectedRoomSubject: {},
    dialogRoomSubjectUpdate: false,
    headers: [
      {
        text: "Subject",
        value: "subject.title",
      },
      {
        text: "Teachers",
        value: "teachers",
        sortable: false,
        align: "center",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
      },
    ],
  }),

  async mounted() {
    await this.loadRoomDetails();
  },
  methods: {
    loadRoomDetails() {
      return this.$axios
        .get(`/rooms/${this.$route.params.id}`)
        .then((response) => {
          this.room = response.data;
        });
    },
    loadRoomSubjectTeachers() {
      return this.$axios.get(`/room-subject-teachers`).then((response) => {
        this.room_subjects = response.data.results;
      });
    },
    showRoomSubjectUpdate(item) {
      this.selectedRoomSubject = item;
      this.dialogRoomSubjectUpdate = true;
    },
  },
};
</script>

<style>
</style>