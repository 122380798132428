<template>
  <v-autocomplete
    :label="label"
    :items="filteredUsers"
    item-text="full_name"
    item-value="id"
    @input="handleInput"
    v-model="user"
    :return-object="returnObject"
    :multiple="multiple"
  ></v-autocomplete>
</template>

<script>
export default {
  props: {
    teacher: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "User",
    },
    value: {
      default: "",
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    user: "",
    users: [],
  }),
  computed:{
    filteredUsers(){
      if(this.teacher){
        return this.users.filter(user => ['TEACHER','ADMIN'].includes(user.user_type))
      }
      else{
        return this.users
      }
    }
  },

  async mounted() {
    await this.loadSubjects();
    this.user = this.value;
  },

  methods: {
    handleInput() {
      this.$emit("input", this.user);
    },
    loadSubjects() {
      return this.$axios.get(`/users`).then((response) => {
        this.users = response.data.results;
      });
    },
  },
};
</script>

<style>
</style>