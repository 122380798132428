<template>
  <v-card class="pa-0">
    <v-toolbar>
      <v-toolbar-title>Update Subject</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row class="ma-4">
      <v-col>
        <subject-auto-complete
          v-model="item.subject"
          :returnObject="true"
        ></subject-auto-complete>
        <users-autocomplete
          v-model="item.teachers"
          label="Select Teachers"
          :returnObject="true"
          :multiple="true"
          :teacher="true"
        ></users-autocomplete
      ></v-col>
    </v-row>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn :loading="loading" @click="updateRoomSubject">Save</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import SubjectAutoComplete from "@/components/SubjectAutoComplete.vue";
import UsersAutocomplete from "@/components/UsersAutoComplete.vue";
export default {
  components: { SubjectAutoComplete, UsersAutocomplete },
  emits: ["close"],
  props: {
    roomSubject: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    item: {},
    loadingSubjects: false,
    loading: false,
  }),
  created() {
    this.item = this.roomSubject;
  },
  async mounted() {},
  beforeDestroy() {
    this.item = {};
  },
  methods: {
    updateRoomSubject() {
      this.loading = true;
      this.$axios
        .put(`/rooms/room-subjects/${this.item.id}/`, {
          ...this.item,
          subject: this.item.subject.id,
          teachers: this.item.teachers.map((teacher) => teacher.id),
        })
        .then(() => {
          this.$emit("close");
        })
        .catch((error) => {
          alert(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>
</style>